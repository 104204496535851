.cso-card-container {
    margin-bottom: 30px;
    background-color: #f9fcfa;

    &.no-action {
        cursor: auto;
    }
}

// heading
.cso-subheading {
    height: 130px;

    h3 {
        position: absolute;
        bottom: 20px;
        padding: 0 $page-horizontal-padding;
        font-size: 350%;
        color: white;
        line-height: 1.2;
    }

    &.light-green {
        background-color: $green-button;
    }
    &.green {
        background-color: $green-navbar-background;
    }
    &.dark-green {
        background-color: $green-background;
    }
    &.bright-green {
        background-color: $light-green-button;
    }
}

// text
.cso-body {
    padding: 30px $page-horizontal-padding;

    .wysiwyg {
        &.limited {
            max-height: 126px;
            overflow: hidden;
        }
    }
}

// button
.cso-button {
    margin-top: 30px;
}

// image
.cso-image {
    width: 100%;
    height: 130px;

    &.large {
        height: 250px;
    }
}


.cso-bottom-image {
    margin-bottom: -56px;
}

.cso-modal-overlay {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 1;
        z-index: 10;
        overflow: hidden;
        transition: opacity 0.2s ease-in;
    
        &.hidden {
            width: 0;
            opacity: 0;
        }
    
        .modal-body {
            background-color: white;
            max-height: 90%;
            width: 90%;
            padding: 20px 20px;
            overflow-y: auto;
            border-radius: $border-radius;
    
            &.small {
                max-width: 600px;
            }
    
            &.medium {
                max-width: 900px;
            }
    
            &.large {
                max-width: 1200px;
            }
        }
    }


.cso-modal-body {
    position: absolute;
    background-color: white;
    z-index: 15;
    width: 200%;
    overflow-y: auto;
    overflow-y: auto;
    border-radius: $border-radius;
    top: 0;

    &.light-green {
        background-color: #d6ebdc;
    }
    &.grey {
        background-color: #e9f0ea;
    }
    &.white {
        background-color: #f9fcfa;
    }

    &.left {
       left: 0;
    }
    &.centre {
        left: -50%;
    }
    &.right {
        right: 0;
    }
    
}


@include screenSize(ptTablet) {
    .cso-subheading {
        & h3 {
            font-size: 300%;
            bottom: 10px;
            left: -15px;
        }
    }

    .cso-body {
        padding: 20px 30px;
    }
}

@include screenSize(lgMobile) {
    .cso-card-container {
        margin-bottom: 15px;
    }
    .cso-subheading {
        height: auto;
        padding: 20px 0;

        h3 {
            position: relative;
            bottom: auto;
            left: auto;
            padding: 0 15px;
        }
    }
    .cso-modal-body {
        width: 100%;

        &.centre {
            left: 0;
        }
    }

    .cso-body {
        padding: 20px 15px;

        .wysiwyg {
            &.limited {
                max-height: none;
                overflow: visible;
            }
        }
    }

    .cso-button {
        width: 100%;
    }
}