// events list
.events-list {
    .event {
        width: calc(50% - 20px);
        margin-bottom: 40px;
        background-color: #b2c1b7;
        
        .image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% / 3);
            height: 100%;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .button {
                padding: 15px;
                position: absolute;
                width: 80%;
                left: 10%;
                bottom: 30px;
                color: white;
            }
        }

        .content {
            padding: 30px;
            padding-left: calc(100% / (12 / 4) + 30px);

            .white {
                color: white;
            }
            
            h3 {
                color: $green-title;
            }

            .button {
                margin-top: 30px;
                color: white;
                text-decoration: none;
            }
        }

    }
}

@include screenSize(ptTablet) {
    .events-list {
        .event {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}

@include screenSize(lgMobile) {
   .events-list {
       padding: 0 15px;

       .event {
            .image-wrapper {
                display: none;
            }

            .content {
                padding: 20px;
            }
        }
    }
}
