.dashboard-container {
    color: $green-background;

    &.page-margin-top {
        margin-top: 48px;
    }

    &.page-margin-bottom {
        margin-bottom: 48px;
    }

    a {
        text-decoration: none;
        outline: none;
        border: none;
    }

    .title {
        font-size: 300%;
        
        &.margin-bottom {
            margin-bottom: 24px;
        }
    }

    .generic-text {
        font-size: 180%;
        margin: 24px 0;

        &.center {
            text-align: center;
        }

        .separator {
            margin: 0 6px;
        }
    }

    .buttons {
                
        &.spacing {

            button {

                &:last-child {
                    margin-left: 16px;
                }
            }
        }
    }

    .dashboard-header {
        
        .header {
            padding-top: 88px;
            padding-bottom: 44px;
        }
    }

    .dashboard-list-container {

        .dashboard-list-item-container {
            margin: 0 -12px;
            padding: 16px 12px;
        }
    }

    .list-header-container {
        margin-bottom: 24px;

        .list-title {
            font-size: 180%;
        }
    }
}

@include screenSize(lsTablet) {
    .dashboard-container {
        .dashboard-header {
            .header {
                padding-top: 0;
            }
        }
    }
}