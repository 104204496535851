.networking-pods {
    .item {
        text-decoration: none;
        min-height: 490px;
        padding: 20px 40px;
        background-color: $green-background;
        color: white;
        cursor: pointer;
        border-right: 1px solid white;
        
        &:last-child {
            border-right: none;
        }

        &.inactive {
            opacity: 0.4;
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        }
    }

    .title {
        color: $orange-title;
        font-size: 350%;
        line-height: 1.2;
    }

    .summary {
        min-height: 84px;
        margin: 15px 0;
        font-size: 160%;
    }

    .arrow {
        font-size: 400%;
        color: $orange-title;
    }


    .caret {
        font-size: 400%;
        color: $orange-title;
    }
}


// pod description
.networking-pod-description {
    .content {
        padding: 30px 40px;
    }
    
    .image {
        img {
            width: 100%;
        }
    }
}

// How it works info box
.expand-icon {
    color: white;
    font-size: 320%;
}

.how-it-works-wrapper {
    border: 2px solid $green-page-title;
    padding: 30px 0;
    line-height: 1.4;

    ol {
        counter-reset: list-counter;
    }
    ol li {
        counter-increment: list-counter;
    }
    li {
        margin: 30px 0;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    ol li::before {
        content: counter(list-counter);
        color: white;
        font-weight: bold;
        position: absolute;
        --size: 32px;
        left: calc(-1 * var(--size) - 10px);
        line-height: 40px;
        width: 40px;
        height: 40px;
        background: $green-page-title;
        border-radius: 50%;
        text-align: center;
        box-shadow: 1px 1px 0 #999;
        font-size: 20px;
        top: -8px;
    }

    .info-section {
        padding-right: 50px;
    }

    .number-section {
        padding-left: 50px;
    }
}

@include screenSize(lsTablet) {
    .networking-pods {
        .item {
            min-height: 300px;
        }

        .copy-wrapper {
            .title {
                font-size: 250%;
                text-shadow: 1px 1px 2px rgba(0,0,0, 0.50);
            }

            .summary {
                font-size: 220%;
            }
        }
    }
}

@include screenSize(ptTablet) {
    .networking-pods {
        .item {
            padding: 20px 30px;
        }
    }
}

@include screenSize(lgMobile) {
    .networking-pods {
        .item {
            padding: 20px 15px;
            min-height: 350px;
            margin-top: 15px;
            border-right: none;
        }
    }

    .networking-hub-single {
        margin-top: 30px;
    }

    .how-it-works-wrapper {
        padding: 15px 0;

        .info-section {
            padding-right: 0;
            margin-bottom: 30px;
        }
    
        .number-section {
            padding-left: 40px;
            margin-bottom: 15px;
        }
    }
}