.form-field {
    &.no-margin{
        margin: 0!important;
        
        & > .form-field {
            margin: 0!important;
        }
    }

    &.wysiwyg-field {
        strong {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }
    }
}

@include screenSize(lgMobile) {
    .form-row {
        .form-field {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .form-field {
        margin-bottom: 10px;
        &.wysiwyg-field {
            margin-bottom: 20px;
        }
    }
}