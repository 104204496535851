.connection-requests-container {
    
    .connections-container {
        .connection-column {
            margin-top: 30px;

            &:first-child {
                .connection-list-container {
                    margin-left: 0;
                }
            }

            &:last-child {
                .connection-list-container {
                    margin-right: 0;
                }
            }
        }
    }

    .connection-list-container {
        margin: 0 20px;

        .list-title {
            font-size: 260%;
        }

        .dashboard-list-container {

            .dashboard-list-item-container {
                margin: 0 -12px;
                padding: 16px 12px;

                &:nth-child(odd) {
                    background-color: $green-card-background;
                }

                .list-item-title {
                    font-size: 160%;
                }
            }
        }
    }
}

.view-connection-modal {
    
    .modal-field-container {
        margin-bottom: 28px;
        font-size: 160%;

        .modal-field-title {
            margin-bottom: 8px;
            font-weight: 500;
        }

        .modal-field {
            display: block;
            padding: 16px;
            background-color: $modal-field;
            box-shadow: 4px 3px 8px 2px rgba(0,0,0,0.3);
    
            &:last-child {
                margin-bottom: 0;
            }
    
            .status {
                display: flex;
                align-items: center;
    
                button {
                    font-size: 100%;
                    margin-left: 16px;
                    padding: 0 46px;
                }
            }
        }
    }
}

@include screenSize(ptTablet) {
    .connection-requests-container {
        .connection-list-container {
            margin: 0;

            .dashboard-list-container {
                .dashboard-list-item-container {
                    margin: 0;
                }
            }
        }
    }
}

@include screenSize(lgMobile) {
    .connection-requests-container {
        .connection-list-container {
            .dashboard-list-container {
                .dashboard-list-item-container {
                    .buttons {
                        margin-top: 20px;

                        .button {
                            margin: 0;

                            &:first-child {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}