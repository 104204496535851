// row
.form-row {
    display: flex;
    justify-content: space-between;

    .form-field {
        flex: 1;
        margin: {
            left: 10px;
            right: 10px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

    }
}

// field
.form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 14px;
    
    &.wysiwyg-field {
        margin-bottom: 20px;
    }
}

// label
.form-label {
    margin-bottom: 5px;
}

// asterisk
.form-asterisk {
    margin-left: 2.5px;
    color: #aaa;
}

// error
.form-error {
    margin-top: 5px;
    color: $form-error;
}

.form-generic-error {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid $form-error;
    border-radius: $border-radius;
    background-color: rgba(234, 123, 115, 0.18);
    font-size: 14px;
    text-align: center;
}

// inputs, textarea and select
.form-input, .form-select {
    padding: 12px 15px;
    @include mainFont();
    font-size: 13px;
    line-height: 24px;
    color: $main-text;
    background-color: white;
    border: 1px solid $form-border;
    border-radius: $border-radius;
    transition: border-color 0.2s ease-in-out;

    &:disabled, &.disabled {
        cursor: not-allowed;
        background-color: #f2f2f2;
    }

    &.error {
        border-color: $form-error;
    }

    // mini version
    &.mini {
        padding: {
            top: 7px;
            bottom: 7px;
        }
    }
}

// input and textarea
.form-input {
    outline: none;

    &.text-area {
        resize: none;
    }

    &:focus {
        border-color: $form-highlight;
    }

    &::placeholder {
        color: #999;
    }
}

// select
.form-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;

    .arrow {
        padding-top: 4px;
        margin-left: 10px;
        padding-left: 10px;
        font-size: 22px;
        border-left: 1px solid #ccc;
    }

    p {
        flex: 1;

        &.placeholder {
            color: #999;
        }
    }

    .remove {
        margin-top: 2px;
        background-color: transparent;
        outline: none;

        i {
            font-size: 16px;
        }
    }

    // multi select
    &.multi-select {
        padding: 3px 15px 3px 5px;

        .selected {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
        }

        .placeholder {
            padding: 10px;
            padding-right: 0;
        }

        .option {
            display: flex;
            margin: 5px;
            padding: 5px 10px;
            background-color: #eee;
            border-radius: $border-radius;

            .remove {
                margin-left: 5px;

                i {
                    font-size: 14px;
                }
            }
        }

        &.disabled {
            .option {
                background-color: white;
            }
        }
    }

    // mini version
    &.mini {
        .arrow {
            margin-left: 30px;
        }
    }
}

// select options & search
.form-select-options {
    position: absolute;
    width: 100%;
    min-width: 400px;
    top: 100%;
    margin-top: 5px;
    background-color: white;
    border: 1px solid $form-border;
    border-radius: $border-radius;
    z-index: 9999;

    .search {
        padding: 15px;

        input {
            width: 100%;
            padding: 5px 15px;
        }
    }

    .options-list {
        max-height: 175px;
        overflow: auto;

        p {
            padding: 8px 15px;
        }

        .option {
            font-size: 13px;
            cursor: pointer;

            &:hover, &.active {
                background-color: #eee;
            }
        }
    }

    // mini version
    &.mini {
        min-width: 200px;
    }
}

// checkbox and radio buttons
.form-checkbox, .form-radio {
    input {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .content {
        display: flex;
        align-items: center;

        .outer-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border: 1px solid $form-border;
            background-color: white;
        }

        .inner-box {
            display: none;

            &.active {
                display: block;
            }
        }

        p {
            flex: 1;
            margin-left: 10px;
        }

        &.disabled {
            cursor: not-allowed;
            
            .outer-box {
                background-color: #f2f2f2;
            }

            p {
                opacity: 0.5;
            }
        }
    }
}

// checkbox
.form-checkbox {
    .content {
        .outer-box {
            border-radius: $border-radius;
        }

        .inner-box {
            font-size: 18px;
        }
    }
}

// radio buttons
.form-radio-list {
    display: flex;
    flex-wrap: wrap;
}

.form-radio {
    margin-right: 20px;
    margin-bottom: 15px;

    .content {
        .outer-box {
            border-radius: 50%;
        }

        .inner-box {
            width: 13px;
            height: 13px;
            background-color: $main-text;
            border-radius: 50%;
        }
    }
}

// datepicker
.form-datepicker {
    display: flex;
    align-items: center;

    .form-input {
        width: 100%;
        padding-right: 40px;
    }

    .remove {
        position: absolute;
        right: 48px;
        margin-top: 4px;
        background-color: transparent;
        outline: none;

        i {
            font-size: 16px;
        }
    }

    .calendar {
        position: absolute;
        right: 15px;
        margin-top: 2px;
        padding: 5px 0 5px 10px;
        background-color: transparent;
        border-left: 1px solid #ccc;
        outline: none;
    }

    &.disabled {
        .calendar {
            cursor: not-allowed;
        }
    }
}

// datepicker widget
.react-datepicker__tab-loop {
    position: static;
}

// file uploader
.file-drop-container {
    border-radius: 2.5px;
    padding: 10px;
    border: 1px solid #cccccc;
    transition: 0.25s background-color ease-in-out, 0.25s box-shadow ease-in-out, 0.25s border ease-in-out;

    &.can-drop {
        -webkit-box-shadow: inset 0px 0px 12px 0px #a5a5a5;
        -moz-box-shadow: inset 0px 0px 12px 0px #a5a5a5;
        box-shadow: inset 0px 0px 12px 0px #a5a5a5;
        &.file-over {
            background-color: #e6e6e6;
        }
    }
    p {
        text-align: center;
        .button {
            font-size: 12px;
            margin-left: 2.5px;
            margin-top: 10px;
            padding: 10px 35px;
        }
    }
    &.size-lg-12 {
        width: 100%;
    } 
}

.file-uploader {
    .button {
        font-size: 12px;
        margin-left: 2.5px;
        margin-top: 10px;
        padding: 10px 35px;

        .icon {
            font-size: 14px;
        }

        &.delete {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
    .file-item,
    .image-item {
        border-radius: 2.5px;
        margin: 2.5px 0;
        padding: 5px 7.5px;
        text-align: center;
        background-color: black;

        img {
            float: none;
            display: inline-block;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &:first-of-type {
            margin-top: 10px;
        }
    }
    .file-name {
        text-align: left;
        color: white;
        margin-top: 2.5px;
        margin-bottom: 2px;
    }

    .progress {
        text-align: center;
        padding: 5px;
        background-color: black;
        margin-top: 10px;
        border-radius: 2.5px;

        p {
            color: white;
            line-height: 1.6;
        }

        .button {
            margin-bottom: 5px;
        }
    }
}