.breadcrumb {
    width: 100%;
    background-color: $green-background;
    color: white;
    font-size: 130%;
    margin-bottom: 35px;

    .container {
        padding: 12px $page-horizontal-padding 13px;
    }

    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .highlighted {
        color: $orange-title;
    }

    @include screenSize(lgMobile) {
        .container {
            padding: 12px 20px 13px; 
        }
    }
}
