// post content
.post-content {
    // pinned icon
    .pinned-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 140%;
    }

    // post details
    .post-details {
        font-size: 160%;

        strong {
            @include mainFontSemiBold;
        }

        &.margined {
            margin-bottom: 20px;
        }
    }

    // tags
    .tag-wrapper {
        margin-bottom: 15px;
        
        .tag {
            font-size: 120%;
            @include mainFontSemiBold;
            background-color: $bright-green-background;
            margin: 5px 5px 0 0;
            padding: 5px;
        }
    }
    @include screenSize(ptTablet) {
        .pinned-icon {
            right: 20px;
        }
    }
}

// entire list
.list-card-container {
    width: calc(100% / 3);
    min-height: 260px;
    margin-bottom: 20px;
    background-color: #ffffff;
    color: $dark-green-text;
    -webkit-box-shadow: 0px 0px 5px 0px #a5a5a5;
    -moz-box-shadow: 0px 0px 5px 0px #a5a5a5;
    box-shadow: 0px 0px 5px 0px #a5a5a5;

    &.full-list {
        .post-content {
            flex-basis: 100%;
        }

        &:nth-child(4n + 1) {
            width: 100%;

            .post-content {
                flex-basis: calc(100% / (12 / 8));
            }

            .image-wrapper {
                display: block;
            }

            .icons-container {
                display: flex;
            }
        }

        // variations of background colour

        &:nth-child(8n + 2) {
            background-color: $green-card-background;
        }
        &:nth-child(8n + 4) {
            background-color: $grey-card-background;
        }
        &:nth-child(8n + 6) {
            background-color: $grey-card-background;
        }
        &:nth-child(8n + 8) {
            background-color: $green-card-background;
        }

        // Reverse formatting

        &:nth-child(8n + 1) {
            flex-direction: row-reverse;
        }
    }

    &.related-list {
        margin-bottom: 0;

        &:nth-child(1) {
            background-color: $grey-card-background;
        }

        &:nth-child(3) {
            background-color: $green-card-background;
        }
    }

    // main content
    .post-content {
        padding: 25px 40px;
    }

    // image styling
    .image-wrapper {
        display: none;
        flex-basis: calc(100% / (12 / 4));
        background-size: cover;
    }

    @include screenSize(ptTablet) {
        .post-content {
            padding: 20px 30px;
        }
    }

    @include screenSize(lgMobile) {
        width: 100%;
        min-height: 0;
        
        &.related-list{
            margin-bottom: 20px;
        }

        .post-content {
            flex-basis: 100%!important;
            padding: 20px 15px;
        }
        
        .image-wrapper {
            display: none!important;
        }
        
        .icons-container {
            display: flex;
        }

        &:nth-child(odd)  {
            flex-direction: row-reverse!important;
        }

        &:nth-child(even)  {
            flex-direction: row!important;
        }
    }
}