.home-container {

    .pod-links-container {
        margin-top: 42px;

        .item {
            min-height: 300px;
            padding: 25px;
            background-color: $green-navbar-background;
            color: white;
            cursor: pointer;
            margin-right: 20px;
            overflow: hidden;

            &:last-child {
                margin-right: 0;
            }
        }

        .image {
            position: absolute;
            top: -1px;
            left: -1px;
            width: 102%;
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        }

        .title {
            font-size: 270%;
            line-height: 1.2;
        }
    }
}

@include screenSize(smDesktop) {
    .home-container {
        .pod-links-container {
            .item {
                min-height: 230px;
            }
        }
    }
}

@include screenSize(ptTablet) {
    .home-container {
        .pod-links-container {
            align-items: center;
            .item {
                width: 100%;
                min-height: 300px;
                margin: 15px 0 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}