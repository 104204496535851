.form-datepicker {
    &.grey {
        .form-input {

            &::placeholder {
                color: black;
            }
        }
    }
}

.react-datepicker-popper {
    z-index: 2;
}