.carousel-container {
    width: 80%;

    .carousel-button {
        position: absolute;
        top: 25%;
        font-size: 350%;
        color: $active-button;
        background-color: transparent;
        
        &.back {
            left: -5%;
        }
        
        &.next {
            right: -5%;
        }

        &:disabled {
            color: $disabled-button;
        }
    }
    //the below class targets and removes a focus ring that appears when focusing on carousel item
    .focusRing___1airF.carousel__slide-focus-ring { 
        outline: none !important; 
    }
}

@include screenSize(smDesktop) {
    .carousel-container {
        .carousel-button {
            &.back {
                left: -10%;
            }

            &.next {
                right: -10%;
            }
        }
    }
}
@include screenSize(lgMobile) {
    .carousel-container {
        .carousel-button {
            &.back {
                left: -15%;
            }

            &.next {
                right: -15%;
            }
        }
    }
}