.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $overlay-grey;
    opacity: 1;
    z-index: 10;
    overflow: hidden;
    transition: opacity 0.2s ease-in;

    &.hidden {
        width: 0;
        opacity: 0;
    }

    .modal-body {
        background-color: white;
        max-height: 90%;
        width: 90%;
        padding: 20px 20px;
        overflow-y: auto;
        border-radius: $border-radius;

        &.small {
            max-width: 600px;
        }

        &.medium {
            max-width: 900px;
        }

        &.large {
            max-width: 1200px;
        }
    }
}