.registration-modal {

    .registration-modal-body {
        height: 450px;
        width: 400px;
        border-radius: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        & > h2 {
            font-size: 280%;
            color: $orange-title;
            margin: 25px 0 300px;
        }

        & .button {
            color: $green-highlight;
            border: 2px solid $green-highlight;
            font-size: 240%;
            padding: 20px;
        }
    }
}

@include screenSize(lgMobile) {
    .registration-modal {
        .registration-modal-body {
            height: 300px;
            width: 250px;

            & > h2 {
                font-size: 230%;
                margin: 10px 0 180px;
            }

            & .button {
                font-size: 200%;
            }
        }
    }
}