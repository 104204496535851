@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

// colours
$button-grey: #dddddd;
$button-blue: #007bff;
$button-green: #28a745;
$button-yellow: #ffc107;
$button-red: #dc3545;
$form-highlight: #222222;
$form-border: #cccccc;
$form-error: #ea7b73;
$main-text: #222222;
$overlay-grey: rgba(0, 0, 0, 0.3);


// fonts
@mixin mainFont() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin mainFontLight() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

@mixin mainFontSemiBold() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

// misc
$border-radius: 3px;