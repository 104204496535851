.our-mission-box-container {
    background-color: $green-navbar-background;
    padding: 20px 0 40px;

    .title {
        color: $orange-background;
        font-size: 360%;
        margin: 20px 0;
        @include mainFont;
    }

    .description {
        color: white;
        font-size: 180%;
        margin: 20px 10%;
        @include mainFont;
        text-align: center;
        line-height: 1.4;
    }

    .list-box {
        background-color: $medium-green-background;
        margin: 20px 5%;
        padding: 25px 90px;
        @include mainFont;
        line-height: 1.8;

        ul {
            list-style: none;
            color: white;
            font-size: 180%;

            li::before {
                content: "\2022"; 
                color:$orange-background;
                @include mainFontBold;
                margin-right: 20px;
            }

            li {
                text-indent: -27px;

                strong {
                    @include mainFontBold;
                }
            }
        }
    }

}

.our-team-container {
    background-color: $light-grey-background;

    .title {
        color: $green-navbar-background;
        font-size: 360%;
        margin: 70px 0;
    }

    .our-team-carousel {
        .our-team-slider {
            margin-bottom: 60px;
        }

        .team-carousel-item {
            margin: 0 15px;
            text-decoration: none;

            .image-wrapper {

                img {
                    border: 2px solid $green-page-title;
                    width: 100%;
                }
            }

            .content-wrapper {
                font-size: 180%;
                text-align: center;
                line-height: 1.4;
                margin-bottom: 25px;

                h3 {
                    color: $green-page-title;
                    @include mainFontBold;
                }
                
                h4 {
                    color: black;
                    @include mainFontBold;
                }

                p {
                    color: black;
                }
            }
        }
    }
}



.founding-members-container {
    background-color: white;

    .title {
        color: $green-navbar-background;
        font-size: 300%;
        margin: 70px 0 40px;
        @include mainFontSemiBold;
    }
}

.founding-members-carousel {
    .founding-members-slider {
        margin-bottom: 60px;
    }
    .image-wrapper {
        height: 100px;
        max-width: 200px;
        margin: 0 15px;

        img {
            width: 100%; 
            height: 100%;
            object-fit: contain;
        }
    }
}

@include screenSize(smDesktop) {
    .our-team-carousel {
        .our-team-slider {
            margin-bottom: 25px;
        }
    }
}


@include screenSize(lgMobile) {
    .list-box {
        padding: 25px 30px 25px 40px !important ;
    }
}
