footer {
    background-color: $green-background;
    color: white;

    .container {
        padding: {
            top: 40px;
            bottom: 40px;
        };
    }

    // contact info
    .contact-wrapper {
        height: 100%;
    
        img {
            height: 18px;
            margin-left: 8px;
        }
    
        p, a {
            text-decoration: none;
            margin-right: 5px;
            color: $orange-title;
            font-size: 160%;
        }
    }

    // logos
    .logos-wrapper {
        margin: 15px 0;

        img {
            height: 70px;
            margin-right: 50px;
        }
    }

    // cookies & privacy
    .info-message {
        font-size: 120%;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include screenSize(lsTablet) {
    footer {
        .logos-wrapper {
            img {
                height: 50px;
                margin: 10px 15px;
            }
        }

        .info-message {
            text-align: center;
        }
    }
}