.funding-list {
    .funding-item {
        min-height: 260px;
        margin-bottom: 30px;
        background-color: $green-card-background;

        &:nth-child(odd) {
            background-color: $green-background;
            flex-direction: row-reverse;
            
            .wysiwyg {
                color: white;
            }
        }

        .content {
            padding: $page-horizontal-padding;

            .button-row {
                margin-top: 30px;
            }
        }

        .image-wrapper {
            background-size: cover;
        }
    }
}

@include screenSize(ptTablet) {
    .funding-list {
        .funding-item {
            .content {
                padding: 30px;
            }
        }
    }
}

@include screenSize(lgMobile) {
    .funding-list {
        margin-top: 15px;

        .funding-item {
            min-height: 0;
            margin-bottom: 15px;

            .content {
                padding: 30px 15px;
            }

            &:last-child {
                margin-bottom: 30px;
            }
        }
    }
}