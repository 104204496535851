// header
.header {
    // account row
    .account-row {
        z-index: 1;

        .container {
            padding: 0 15px;
            height: 64px;
        }

        .button {
            margin-left: 15px;
            width: 154px;

            &.grey {
                background-color: #D0D0D0
            }
        }
    }

    // logged in account details
    .account-details {
        font-size: 140%;
        line-height: 13px;
        color: $grey-account-row;

        a {
            color: $main-text;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .unstyled-button {
            &:hover {
                text-decoration: underline;
            }
        }

        .divider {
            margin: 0 15px;
        }

        .icon-wrapper {
            margin-right: 6px;

            & i {
                font-size: 24px;
                color: #515151;
            }
            
            &:hover {
                text-decoration: none;
            }
        }
    }

    // logo and navigation row
    .header-row {
        .nav-background {
            position: absolute;
            top: 50%;
            right: 0;
            width: 50%;
            height: 80px;
            margin-top: -40px;
            background-color: $green-navbar-background;
        }
    }

    // logo
    .logo {
        position: absolute;
        width: calc(100% / 3);
        left: 0;
        padding: 0 30px 0 $page-horizontal-padding;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

// navigation
.navigation {
    height: 80px;
    background-color: $green-navbar-background;
    z-index: 1;

    .nav-item {
        a {
            padding: 0 15px;
            color: white;
            text-decoration: none;
            transition: background-color 0.2s ease-in-out;
            font-size: 140%;

            &:hover,&.active {
                background-color: $green-highlight;
            }
        }

        .sub-nav-container {
            visibility: hidden;
            position: absolute;
            background-color: $green-navbar-background;
            top: 100%;
            left: 0;
            width: 150%;

            & > .sub-nav-item {
                height: 45px;

                &:hover {
                    background-color: white;
                    color: $green-navbar-background;
                }
            }
        }

        &:hover {
            & .sub-nav-container{
                visibility: visible;
            }
        }
    }
}

// header mobile
.header-mobile {
    border-bottom: 10px solid $green-background;

    .container {
        padding: 15px;
    }

    .logo {
        img {
            height: 80px;
        }
    }

    .menu-icon {
        padding: 0;
        margin-right: 10px;
        font-size: 280%;
        background-color: transparent;
        outline: none;
    }

    .logged-in-as {
        padding: 5px;
        font-size: 140%;
        text-align: center;
        color: $grey-title;
        background-color: #f2f2f2;
    }
}

// navigation mobile
.navigation-mobile {
    position: fixed;
    padding: 75px 30px 30px;
    @include fullWidth;
    z-index: 999;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    background-color: rgba(18, 56, 29, 0.95);

    &.active {
        transform: translateX(0);
    }

    .close-menu {
        position: absolute;
        top: 30px;
        right: 15px;
        background-color: transparent;
        outline: none;
        color: white;
        font-size: 420%;
    }

    .nav-item {
        display: block;
        border-bottom: 1px solid $green-background;

        a, button {
            display: block;
            width: 100%;
            padding: 15px;
            text-align: center;
            color: white;
            text-decoration: none;
            font-size: 320%;
            background-color: transparent;
            outline: none;
            @include mainFont();

            &.active {
                color: $green-highlight;
            }

            &.sub-nav-item {
                padding: 10px 15px;
                font-size: 280%;
                color: #ccc;

                &:last-child {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

@include screenSize(lgDesktop) {
    .navigation {
        .nav-item {
            a {
                padding: 0 10px;
                font-size: 130%;
            }
        }
    }
}

@include screenSize(smDesktop) {
    .header {
        .logo {
            width: calc(100% / 4);
            padding: 0 20px;
        }
    }
}

@include screenSize(lgMobile) {
    .header-mobile {
        .logo {
            img {
                height: 60px;
            }
        }

        .menu-icon {
            font-size: 240%;
        }
    }

    .navigation-mobile {
        font-size: 50%;
        padding: 50px 15px 30px;

        .close-menu {
            top: 15px;
        }

        .nav-item {
            a, button {
                padding: 10px 15px;
            }
        }
    }
}