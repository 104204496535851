// pods
.home-pods {
    .item {
        min-height: 490px;
        padding: 20px 40px;
        background-color: $green-background;
        color: white;
        cursor: pointer;
        border-right: 1px solid white;
        
        &:last-child {
            border-right: none;
        }

        &.inactive {
            opacity: 0.4;
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        }
    }

    .title {
        color: $orange-title;
        font-size: 350%;
        line-height: 1.2;
    }

    .summary {
        min-height: 84px;
        margin: 15px 0;
        font-size: 160%;
    }

    .arrow {
        font-size: 400%;
        color: $orange-title;
    }
}

// pod description
.home-pod-description {
    .content {
        padding: 30px 40px;
    }

    .image {
        img {
            width: 100%;
        }
    }
}

.center-box-container {
    background-color: $green-navbar-background;
    padding: 20px 0;

    .title {
        color: $orange-background;
        font-size: 360%;
        margin: 20px 0;
        @include mainFont;
    }

    .description {
        color: white;
        font-size: 200%;
        @include mainFontSemiBold;
        margin: 20px 10%;
    }

    .info-box {
        background-color: $medium-green-background;
        margin: 20px 10%;
        padding: 25px 90px;

        h3 {
            color: $orange-background;
            font-size: 200%;
            @include mainFontSemiBold;
            margin-bottom: 25px;
        }

        .info-desc {
            .icon {
                color: $orange-background;
                font-size: 180%;
                margin: 5px 10px 0;
            }

            p {
                color: white;
                font-size: 180%;
                @include mainFont;
                margin-bottom: 25px;
            }

            &:first-child {
                margin-right: 80px;
            }
        }
    }

}

.get-started-container {
    background-color: $orange-background;
    padding: 60px 0;
    margin-bottom: 60px;

    h3 {
        font-size: 360%;
        @include mainFontSemiBold;
    }

    p {
        font-size: 280%
    }

    .text-container {
        margin-right: 70px;
    }

    .button {
        width: 154px;

        &:first-child {
            margin-right: 15px;
        }
    }
}

@include screenSize(lsTablet) {
    .home-pods {
        .item {
            min-height: 400px;
        }

        .title {
            font-size: 250%;
        }
    }

    .info-box {
        .info-desc {
            &:first-child {
                margin-right: 0!important;
            }
        }
    }


    .get-started-container {
        .text-container {
            margin: 0px!important;
            text-align: center;
            margin-bottom: 15px !important;

            h3 {
                font-size: 320%;
            }

            p {
                font-size: 260%;
            }
        }

        .button {
            &:first-child {
                margin: 0px 0px 10px !important;
            }
        }
    }
}

@include screenSize(ptTablet) {
    .home-pods {
        .item {
            padding: 20px 30px;
            min-height: 450px;
        }
    }

    .home-pod-description {
        .content {
            padding: 30px;
        }
    }
    
}

@include screenSize(lgMobile) {
    .home-pods {
        .item {
            padding: 20px 15px;
            min-height: 400px;
            margin-top: 15px;
            border-right: none;
        }

        .summary {
            min-height: 0;
        }
    }

    .home-pod-description {
        .content {
            padding: 30px 15px;
        }

        .image {
            padding: 0 15px;
            margin-bottom: 15px;
        }
    }

    .title {
        text-align: center;
    }
    
    .description {
        text-align: center;
        margin: 15px 5%!important;
    }

    .info-box {
        padding: 25px 15px !important;
        margin: 15px 5% !important;
    }

}