.problem-case-studies-container {

    .problem-case-studies-list-container {
        margin: 48px 0;
    }

    .dashboard-list-container {
        columns: 2;
        column-gap: 32px;
        column-fill: auto;

        .dashboard-list-item-container {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid-column;
            
            &:nth-child(2n + 2) {
                background-color: $green-card-background;
            }

            .list-item-title {
                font-size: 160%;
            }
        }
    }
}