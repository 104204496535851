.solution-case-studies-container, .problem-case-studies-container { 
    .list-container {
        margin-bottom: 45px;
    }

    & .sub-title {
        font-size: 260%;
        margin: 45px 0 20px;
    }
}

.case-study-item-wrapper {
    padding: 15px 10px;
    box-shadow: 0px 0px 7px -2px #a5a5a5;
    background-color: white;

    & .content-wrapper {
        white-space: nowrap;

        & .cs-title {
            font-size:160%;
            margin-right: 10px;
        } 
        
        & .date {
            font-size:160%;
            & p {
                @include mainFontBold();
            }

        }
        
        
    }
    
    & .tag-wrapper {
        .tag {
            font-size: 130%;
            @include mainFontSemiBold;
            background-color: $orange-dropdown;
            margin: 5px 5px 0 0;
            padding: 5px;
        }
    }

   &:nth-child(4n + 2) {
       background-color: $green-card-background;
   }
   &:nth-child(4n + 3) {
       background-color: $green-card-background;
   }
}

@include screenSize(ptTablet) {
    .case-study-item-wrapper {
        margin-bottom: 15px;

        .content-wrapper {
            padding-right: 20px;
            white-space: normal;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(odd) {
            background-color: white;
        }

        &:nth-child(even) {
            background-color: $green-card-background;
        }
    }
}

@include screenSize(lgMobile) {
    .case-study-item-wrapper {
        .button-row {
            margin-top: 20px;
        }

        .button {
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}