.auth-subheading {
    background-color: #e8f7eb;
    color: $dark-green-text;
    line-height: 1.7;
    padding: 30px 0;
    margin-bottom: 30px;

    h2 {
        font-size: 260%;
    }

    h3 {
        font-size: 210%;
        @include mainFontBold;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    
    li {
        font-size: 160%;
    }
}

.auth-form-title {
    font-size: 440%;
    margin: 0;
    color: #12301b;
}

.auth-supply-title {
    font-size: 260%;
    margin: 30px 0 20px;
    color: #12301b;

    &:first-child {
        margin-top: 0;
    }
}

.auth-form-field {
   & input, textarea, .form-select {
        border: none;
        -webkit-box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.75);
        box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.75);
        -webkit-appearance: none;
   } 
   
    ::placeholder, .form-select > p {
        color: #1d1d1b;
    }

}

.auth-checkbox {
    font-size: 210%;
    @include mainFontSemiBold;
    color: #002d18;
    margin: 30px 0;

    & .outer-box {
        margin-left: 10px;
    }
    & .form-asterisk {
        color: #1d1d1b;
    }

    .form-error {
        flex-basis: 100%;
        font-size: 14px;
    }
}

.forgot-password-text {
    font-size: 160%;
    color: $dark-green-text;
}

.forgot-password-success {
    margin-top: 50px;
    font-size: 240%;
    @include mainFontSemiBold;
    color: $dark-green-text;
}

@include screenSize(ptTablet) {
    .auth-form-title {
        font-size: 300%;
    }
}

@include screenSize(lgMobile) { 

    .auth-form-title {
        font-size: 280%;
    }
    .auth-subheading {
        h2 {
            font-size: 230%;
        }
    }

    .auth-supply-title {
        font-size: 200%;
    }

    .auth-form-field {
        margin: 0!important;

        &:last-child {
            margin: 10px 0!important;
        }
    }

    .auth-checkbox {
        font-size: 200%;
    }
}