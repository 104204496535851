.button {
    padding: 15px 35px;
    @include mainFontSemiBold();
    border-radius: 0;
    font-size: 160%;
    line-height: 14.5px;
    border-radius: $border-radius;

    &.green {
        background-color: $green-button;
    }

    &.bright-green {
        background-color: $bright-green-background;
    }

    &.purple {
        background-color: $green-background;
        color: white;
    }

    &.light-green {
        background-color: $light-green-button;

        &.block-hover {
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: #087b39;
                opacity: 1;
            }
        }
    }
    
    &.grey {
        background-color: $grey-background;
    }

    &.transparent {
        border: 1px solid $green-button;
        background-color: transparent;
        color: $green-button;
    }    


    //extra classes

    &.wide {
        width: 200px;
    }

    &.small-button {
        height: 32px;
        width: 64px;
        font-size: 140%;
    }

    &.medium-button {
        height: 35px;
        width: 90px;
        font-size: 140%;
    }

    &.margin {
        margin: 40px 0;
    }

    &.margin-left {
        margin-left: 25px;
    }

    &.no-margin {
        margin: 0!important;
    }

    &.normal-font {
        @include mainFont();
        font-weight: 600;
        font-size: 140%;
    }

    &.filter-bar {
        width: 110px;
        height: 43px;
        text-align: center;
        white-space: nowrap;
    }

    &.funding-link {
        width: 271px;
        height: 43px;
        background-color: $green-background;
        color: white;
        text-align: center;
        white-space: nowrap;

        &.margin-right {
            margin-right: 100px;
        }
    }

    &.cursor-initial {
        cursor: initial;
    }
}