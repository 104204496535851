.paragraph-wrapper {
    color: #4a4a4a;
    margin-right: 75px;
}

.wysiwyg h3 {
    ul, li {
        font-size: inherit;
    }
    li {
        padding-left: 1.5em;
    }
    li:before {
        top: 0.4em;
    }
}

.sign-up-box {
    background-color: $green-page-title;
    color: white;
    font-size: 160%;
    padding: 25px 50px;
    width: 50%;

    .button {
        background-color: $green-background;
        color: white;
        margin-top: 25px;
    }

    & a {
        color: purple;
    }
}

.info-wrapper {
    margin-top: 50px;
    
    strong {
        @include mainFontBold;
    }
}

.map-wrapper {
    width: 100%;
}

@include screenSize(lsTablet) {
    .sign-up-box {
        padding: 20px;
    }
}

@include screenSize(ptTablet) {
    .sign-up-box {
        margin-top: 30px;
    }

    .paragraph-wrapper {
        margin-right: 0;
    }

    .info-wrapper {
        margin-top: 30px;
    }

    .map-wrapper {
        margin-top: 30px;
    }
}