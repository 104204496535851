.modal-body {
    .page-title {
        margin-bottom: 10px;
        color: $green-title;
        @include mainFontSemiBold;
        font-weight: initial;
        font-size: 280%;
    }

    .description {
        margin-bottom: 20px;
    }
}

@include screenSize(lgMobile) {
    .modal-body {
        .page-title {
            font-size: 230%;
        }

        .button-row {
            display: flex;
            flex-direction: column-reverse;

            .button {
                margin: 15px 0 0;
            }
        }

        .quill {
            min-height: 150px;
        }

        .ql-container {
            height: auto;
            min-height: 100px;
        }
    }
}