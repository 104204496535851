// colours
$main-text: #222222;

$green-navbar-background: #8A1A9B;
$green-background: #5A0064;
$green-button: #7a1789;
$light-green-button: #BE2BBB;
$bright-green-background: #FF80FF;
$medium-green-background: #923d9f;

$green-highlight: #be2bbb;
$green-title: #91008f;
$green-page-title: #940096;
$dark-green-text: #520d56;
$orange-background: #FF5A5A;
$orange-title: #eb665f;
$grey-title: #999999;
$grey-account-row: #515151;
$grey-background: #b1c0b8;
$light-grey-background: #F2F1F0;
$dark-green-heading-text: #003010;

$green-card-background: #e6d3e9;
$grey-card-background: #efe9f0;

$orange-dropdown: #fbbc4e;
$grey-dropdown: #b2c0b7;

$modal-field: #f5faf6;

$active-button: #910096;
$disabled-button: #DBDBDB;


$overlay-grey: rgba(0, 0, 0, 0.3);

// spacing
$page-horizontal-padding: 60px;

$border-radius: 3px;

// fonts
@mixin mainFont() {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 300;
}

@mixin mainFontSemiBold() {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-family: 400;
}

@mixin mainFontBold() {
    font-family: 'Moderat', 'Arial', sans-serif;
    font-weight: 600;
}

@mixin mainFontHeavy() {
    font-family:'Moderat', 'Arial', sans-serif;
}

@mixin screenSize($size) {
    @if $size == lgDesktop {
        // large desktop
        @media only screen and (max-width: 1599px) {
            @content;
        }
    } @else if $size == mdDesktop {
        // medium desktop
        @media only screen and (max-width: 1279px) {
            @content;
        }
    } @else if $size == smDesktop {
        // small desktop
        @media only screen and (max-width: 1099px) {
            @content;
        }
    } @else if $size == lsTablet {
        // landscape tablet
        @media only screen and (max-width: 1023px) {
            @content;
        }
    } @else if $size == ptTablet {
        // portrait tablet
        @media only screen and (max-width: 800px) {
            @content;
        }
    } @else if $size == lgMobile {
        // large mobile
        @media only screen and (max-width: 767px) {
            @content;
        }
    } @else if $size == mdMobile {
        // medium mobile
        @media only screen and (max-width: 479px) {
            @content;
        }
    } @else if $size == smMobile {
        // small mobile
        @media only screen and (max-width: 413px) {
            @content;
        }
    } @else {
        // custom size
        @media only screen and (max-width: $size) {
            @content;
        }
    }
}

@mixin fullWidth() {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}