.form-select {
    &.multi-select {
        &.grey {
            & .placeholder {
                color: black;
            } 
        }
    }

    &.filter-bar {
        padding: {
            top: 0;
            bottom: 0;
        }
        width: 301px;
        border-radius: 0;

        .placeholder {
            padding: 9px 10px;
        }

        .option {
            padding: 4px 10px;
        }
    }
}

.form-select-options {
    &.grey {
       & > p {
           color: black;
        } 
    }

    
}