.sub-heading-container {
    padding: 40px 0;
    background-color: $green-page-title;
    margin-bottom: 30px;

    &.clickable {
        cursor: pointer;
    }

    .container {
        padding: 0 20px 0 $page-horizontal-padding;
    }

    .sub-heading-title-container {
        h2 {
            font-size: 350%;
            color: white;
        }
        p {
            font-size: 240%;
            color: white;
        }
    }

    .filter-box-wrapper {
        margin-left: 50px;

        .form-field {
            display: block;
        }
    }

    .filter-bar-search {
        &.grey {
            textarea {
                padding: 10px 15px!important;
                width: 301px;
                border-radius: 0;
                height: 46px;
                &::placeholder {
                    color: black;
                }
            } 
        }

        &.margin-left {
            margin-left: 10px;
            margin-bottom: 0;
        }
    }

    .filter-bar {
        &.column {
            margin-bottom: 10px;
        }
    }

    .hub-filters {
        margin-right: 30px;
    }
}

@include screenSize(smDesktop) {
    .funding-link {
        margin-top: 10px;
    }
}

@include screenSize(ptTablet) {
    .sub-heading-container {
        .sub-heading-title-container {
            & h2 {
                font-size: 300%;
            }
        }

        & .filter-bar {
            width: 250px!important;
        }

        & .funding-link {
            margin-right: 10px;
            width: 250px;
            font-size: 140%;
        }

        .hub-filters {
            width: 250px;
            margin-bottom: 10px;
            margin-right: 0;

            textarea {
                width: 100%!important;
            }
        }
    }
}

@include screenSize(lgMobile) {
    .sub-heading-container {
        padding: 20px 0;

        .container {
            padding: 0 30px;
        }

        .sub-heading-title-container {
            white-space: nowrap;
            & h2 {
                font-size: 280%;
            }

            p {
                font-size: 200%;
            }
        }

        .filter-box-wrapper {
            margin-left: 10px;
        }

        .filter-bar {
            width: 100%!important;
            min-width: 0;

            & .placeholder {
                text-align: center;
            }
        }

        .filter-bar-search {
            textarea {
                width: 100%!important;
                margin-top: 10px;

                &::placeholder {
                    text-align: center;
                }
            }

            &.margin-left {
                margin-left: 0;
            }
        }

        & .funding-link {
            margin: 10px 0 0;
            white-space: normal;
            padding: 10px 0;
        }

        .filter-box-wrapper {
            margin-top: 15px;
            margin-left: 0;
        }

        .filter-box-button {
            width: 100%;
            margin-top: 15px;
        }

        .hub-filters {
            width: 100%;
        }
    }

    .expand-icon {
        font-size: 260%;
    }
}

@include screenSize(smMobile) {
    .sub-heading-container {
        .container {
            padding: 0 15px;
        }
        
        .sub-heading-title-container {
            white-space: normal;
            & h2 {
                font-size: 280%;
            }
        }
    }
}