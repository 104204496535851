.page-banner {
    margin-bottom: 35px;

    .content {
        padding-top: 60px;
        padding-left: $page-horizontal-padding;
        padding-right: 20px;

        .title {
            font-size: 350%;
            color: $green-page-title;
        }

        .sub-title {
            color: $grey-title;
            font-size: 350%;
        }
        
    }

    .image {
        height: 430px;
        background-size: cover;
        background-position: center;

        &.top {
            background-position: top;
        }
    }

    &.no-margin {
        margin: 0;
    }
}

@include screenSize(smDesktop) {
    .page-banner {
        .content {
            padding-top: 30px;
        }

        .image {
            height: 350px;
        }
        
        &.no-margin {
            margin-bottom: 35px;
        }
    }
}

@include screenSize(ptTablet) {
    .page-banner {
        .content {
            .title, .sub-title {
                font-size: 250%;
            }
        }
    }
}

@include screenSize(lgMobile) {
    .page-banner {
        margin-bottom: 20px;

        .content {
            padding: 30px 15px 0;
        }

        .title, .sub-title {
            font-size: 230%;
        }

        .image {
            height: 250px;
        }
    }
}

@include screenSize(smMobile) {
    .page-banner {
        .image {
            height: 200px;
        }
    }
}