// row
.button-row {
    display: flex;
    justify-content: center;
    
    .button {
        margin: 0 5px;
    }

    &.left {
        justify-content: flex-start;

        .button:first-child {
            margin-left: 10px;
        }

        &.no-margin {
            .button:first-child {
                margin-left: 0
            }
        }
    }

    &.right {
        justify-content: flex-end;

        .button:last-child {
            margin-right: 0;
        }
    }
}

// button
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    @include mainFont();
    font-size: 14px;
    color: $main-text;
    background-color: $button-grey;
    border-radius: $border-radius;
    outline: none;
    transition: opacity 0.2s ease-in-out;
    text-decoration: none;

    .icon {
        margin-right: 10px;
        margin-top: 2px;
    }

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5!important;
        cursor: not-allowed;
    }

    &.blue, &.green, &.red {
        color: white;
    }

    // blue
    &.blue {
        background-color: $button-blue;
    }

    // green
    &.green {
        background-color: $button-green;
    }

    // yellow
    &.yellow {
        background-color: $button-yellow;
    }

    // red
    &.red {
        background-color: $button-red;
    }
    //orange
    &.orange {
        background-color: #fbbc4e;
    }

}